export const REAL_BRL = {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false
}

export const convertRealToMoney = function (v, enableNegative = false) {
  /*if(!Number.isNaN(v)){
    return v
  }*/
  if (enableNegative) {
    return parseFloat(v.replace("R$", "").replace(/\./g, "").replace(",", ".").trim())
  }
  return Number(String(v).replace(/\D/gi, '')) / 100
}
